export const tables = [
  {
    id: 6326,
    teamname: "1. Herren",
    showRegion: true,
  },
  // {
  //   id: 5414,
  //   teamname: "2. Herren",
  //   showRegion: true,
  // },
]

export const tablesPast = [
  {
    id: 5595,
    teamname: "1. Herren",
    showRegion: true,
  },
  {
    id: 5057,
    teamname: "1. Herren",
    showRegion: true,
  },
  {
    id: 4514,
    teamname: "1. Herren",
    showRegion: true,
  },
  {
    id: 5414,
    teamname: "2. Herren",
    showRegion: true,
  },
  {
    id: 4069,
    teamname: "1. Herren",
    showRegion: true,
  },
  {
    id: 4785,
    teamname: "2. Herren",
    showRegion: true,
  },
  {
    id: 3711,
    teamname: "1. Herren",
    showRegion: true,
  },
  {
    id: 2690,
    teamname: "1. Herren",
    showRegion: true,
  },
  {
    id: 3310,
    teamname: "2. Herren",
    showRegion: false,
  },
  {
    id: 1698,
    teamname: "1. Herren",
    showRegion: true,
  },
  {
    id: 2299,
    teamname: "2. Herren",
    showRegion: true,
  },
  {
    id: 1147,
    teamname: "1. Herren",
    showRegion: true,
  },
  {
    id: 1697,
    teamname: "2. Herren",
    showRegion: true,
  },
  {
    id: 401,
    teamname: "1. Herren",
    showRegion: true,
  },
  {
    id: 1257,
    teamname: "2. Herren",
    showRegion: true,
  },
  {
    id: 254,
    teamname: "1. Herren",
    showRegion: true,
  },
  {
    id: 549,
    teamname: "2. Herren",
    showRegion: true,
  },
]
